import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Link } from 'gatsby';
import Zoom from 'react-reveal/Zoom';
import { OutboundLink } from 'gatsby-plugin-google-analytics';
import Christmas from '../assets/christmas-story-cover.jpg';
import Kayenta from '../assets/ads/Kayenta.jpg';
import CreditUnion from '../assets/ads/university-cu.jpg';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1 {...{
      "id": "tuacahn-amphitheatre",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#tuacahn-amphitheatre",
        "aria-label": "tuacahn amphitheatre permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Tuacahn Amphitheatre`}</h1>
    <blockquote>
      <p parentName="blockquote">{`🔇 We appreciate you silencing your phone.`}</p>
    </blockquote>
    <h2 {...{
      "id": "a-christmas-story",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#a-christmas-story",
        "aria-label": "a christmas story permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`A Christmas Story`}</h2>
    <Link to="/christmas-story" mdxType="Link">
      <img src={Christmas} alt="Tuacahn Amphitheatre A Christmas Story Cover" />
    </Link>
    <br />
    <br />
    <blockquote>
      <p parentName="blockquote">{`📱 Please use the menu to explore our new mobile friendly program.`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`📖 If you prefer a traditional “page turning” format as with print publications, `}<a parentName="p" {...{
          "href": "/digital-program"
        }}>{`please click here`}</a>{`.`}</p>
    </blockquote>
    <OutboundLink href="http://kayentahomes.com/?utm_source=Mills%20Publishing&utm_medium=Online%20Playbill&utm_campaign=Tuacahn" target="_blank" rel="noopener noreferrer" mdxType="OutboundLink">
  <img src={Kayenta} alt="kayenta Homes ad" />
    </OutboundLink>
    <br />
    <br />
    <OutboundLink href="https://ucreditu.com/?utm_source=Mills%20Publishing&utm_medium=Online%20Playbill&utm_campaign=Tuacahn" target="_blank" rel="noopener noreferrer" mdxType="OutboundLink">
  <img src={CreditUnion} alt="University Federal Credit Union" />
    </OutboundLink>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      